* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
}

.page-number-container {
    display: flex;
    justify-content: space-evenly;
    padding: 1.7rem;
    margin-bottom: 3rem;
    margin-top: 2rem;
}

.page-number-container .item-element {
    width: 200px;
    text-align: center;
    border: 0.8px solid rgba(0, 0, 0, 0.599);
    border-radius:50px;
    background-color: #ececec;
    padding: 0.6rem;
    cursor: pointer;
}

.item-element a {
    color: black;
    text-decoration: none;
    font-size: 1.45rem;
    font-weight: 500;
}

.item-element:active {
    background-color: #1c1c1cd3;
}

.item-element a:active {
    color: #f5f5f5;
}
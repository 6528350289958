* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    width: 100%;
    border-top: 1px solid rgb(0, 0 ,0 ,0.12);
    margin-top: 3rem;
}

footer .copyright-text {
    margin-bottom: 1rem;
}

footer .copyright-text p {
    font-size: 1.5rem;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

header {
    display: flex;
    justify-content: space-evenly;
    margin: 2rem;
    padding: 3rem;
    width: 100%;
    border:1px solid #ccc;
    border-radius: 10px;
    background: rgb(2, 0, 36);
    background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(9, 9, 121, 1) 34%, rgba(0, 212, 255, 1) 90%);
}

header .header-name {
    margin-right: 2rem;
    color: #f5f5f5;
}

header .input-search {
    margin-top: auto;
}

header .input-search label {
    font-size: 1.2rem;
    font-weight: 500;
    color: #f5f5f5;
    float: left;
}

header .input-search svg {
    background-color: #2196F3;
    border: none;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    width: 60px;
    transform: translateY(13px);
    padding-top: 0.73rem;
    padding-bottom: 0.90rem;
}

header .input-search input {
    padding: 10px;
    font-size: 20px;
    width: 60%;
    font-family:sans-serif;
    border: 1px solid grey;
    background: #f1f1f1;
    border: none;
    caret-color: rgba(21, 21, 21, 0.395);
}

header .input-search input:hover {
    box-shadow: 7px 7px 8px rgba(0, 0, 0, 0.172);
}

header .input-search button {
    width: 80px;
    padding: 10px;
    background: #2196F3;
    color: white;
    font-size: 17px;
    border: none;
    border-left: none;
    cursor: pointer;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

header .input-search button:active {
    background: #056cbf;
} 